import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

import FormationsDateList from './FormationsDateList'


class FormationList extends React.Component {


  render() {
    const { data } = this.props
    const { edges: formations } = data.formationList
    
    return (
      <div className="formation-column right">
        <div className="section formation-card-section">
          <div className="content-wrapper flex-column justc-start alitems-center">
            <div className="full-width flex-column justc-center alitems-stretch">
              {formations && (formations.map(({ node: formation }) => (
                <Link to={formation.fields.slug}
                      className="formation-list-item " 
                      key={formation.id}>
                  <h2 className="title-5 bold-weight text-light mgb-xs">
                    {formation.frontmatter.title}
                  </h2>
                  <div className="formation-extract mgb-s pdb-s">
                    {formation.frontmatter.extract}
                  </div>
                  <div className="job-subtitle flex-row wrap justc-start alitems-start mgt-xs">
                    <div className="mgb-xs mgr-m">
                      <img className="job-icon" src="/img/icons/location-icon.svg" alt='tag-icon' />
                      {formation.frontmatter.location}
                    </div>
                    <div className="mgb-xs mgr-m">
                      <img className="job-icon" src="/img/icons/participants-icon.svg" alt='tag-icon' />
                      {formation.frontmatter.availableSlots}
                    </div>
                    <div className="dspl-inline-flex justc-start alitems-center mgr-s mgb-xs">
                      <img className="job-icon" src="/img/icons/clock-icon.svg" alt='tag-icon' />
                      {formation.frontmatter.duration}
                    </div>
                    <div className="mgb-xs mgr-m">
                      <img className="job-icon" src="/img/icons/calendar-icon.svg" alt='tag-icon' />
                      <FormationsDateList dates={formation.frontmatter.dates} noDatesMessage={formation.frontmatter.noDatesMessage}/>
                    </div>
                  </div>
                </Link>
              )))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FormationList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default function formationListData() {
  return (
    <StaticQuery
    query={graphql`
    query FormationListQuery {
      formationList : allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "formation-page" } }}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              dates
              noDatesMessage
              availableSlots
              duration
              location
              extract
            }
          }
        }
      }
    }
    `}
    render={(data, count) => (
      <FormationList data={data} count={count} />
    )}
  />
  )
}