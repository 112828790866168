import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";

import Layout from '../components/Layout'
import FormationList from '../components/FormationList'

export const FormationHomeTemplate = ({data}) => {
  const formationData = data.frontmatter

  return (
    <div className="formation-column left">
      <div className="section">
        <div className="content-wrapper flex-column justc-start alitems-start m-alitems-center">
          <div className="full-width mgb-s m-two-third-width m-mgb-m">
            <img className="block-image" src={formationData.image} alt="assistance" />
          </div>
          <h1 className="title-3 bold-weight text-medium mgb-s m-mgb-s s-mgb-xs s-center-text">
            {formationData.title}
          </h1>
          <div className="full-width flex-column alitems-start m-center-text">
            <ReactMarkdown children={formationData.textContent} className="arrow-list paragraph-spacing mgb-m m-mgb-xs" />
          </div>
        </div>
      </div>
    </div>
  )
}


const FormationHome = ({ data }) => {
  const { markdownRemark: pageData, footerData, navbarData } = data;

    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <div className="flex-row alitems-stretch formation-wrapper m-flex-column m-alitems-stretch">    
          <FormationHomeTemplate data={pageData}/>
          <FormationList/>
        </div>
      </Layout>
    );
}

FormationHome.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FormationHome;


export const formationHomeQuery = graphql`
  query FormationHome($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        textContent
      }
    }
    ...LayoutFragment
  }
`;